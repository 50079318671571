<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          
          <CRow>
              <CCol md="4">
                    <h4>Notification Template</h4>
              </CCol>
              <CCol md="5">
                   <CInput label="Search" v-model="filter" @change="getFilterData" placeholder="Type to Search" horizontal />
              </CCol>
                <CCol md="3"> 
                     <b-button variant="primary" to="/notif/template/create" >New Template</b-button>
                </CCol>             
          </CRow>
        </CCardHeader><br/>
        <CCardBody> 

         <CRow>
            <CCol col="12" class="text-left">                   
               <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
            </CCol> 
         </CRow>  

         <div class="text-center">
            <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
         </div>


        <div class="table-responsive scroll-r">
          <b-table striped hover  :items="items" :fields="fields" v-model="list" :current-page="currentPage" :per-page="perPage" :filter="filter"> 

              <template v-slot:cell(createDate)="{ item }"> 
                {{ item.createDate | dateWithTime }}
              </template>

              <template v-slot:cell(actions)="{ item }">         

                <b-dropdown variant="info" text="Actions" size="md"> 
                  <router-link :to='"/notif/template/edit/" + item.id' tag="b-dropdown-item" > Edit </router-link>
                  <router-link to="#" exact v-on:click.native="ActionDelete(item.id)" tag="b-dropdown-item" > Delete </router-link>
                </b-dropdown> 

              </template>

          </b-table>
        </div>

        <div>
          <b-row>
            <b-col sm="3">
               <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
            </b-col>       
            <b-col sm="9">
               <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
               </b-form-select>
            </b-col>
          </b-row>      
        </div>
          
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import AccountService from '@/api/AccountService.js'; 
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';

 export default {
    name: "notif_template",
    components: {
      VueTypeaheadBootstrap,            
    },     
    data() {
      return {
        spinner:false,
        list:[],
        columns : [],
        data : [],
        items:[],             
        fields: [           
          {
            key: 'templateName',
            label : 'Template Name',
            sortable: true
          },         
          {
            key: 'body',
            label : 'Body',
            sortable: true
          }, 
          {
            key: 'title',
            label : 'Title',
            sortable: true
          }, 
          {
            key: 'preview',
            label : 'Preview',
            sortable: true
          },  
          {
            key: 'createDate',
            label : 'Created Date',
            sortable: true
          },   
          {            
            key: 'actions',
            label: 'Actions'         
          }                   
        ],         
        currentPage: 1,
        perPage: 10,
        filter: null,
        seen:false,
        msg :'',
        color:''
      };
    },    
    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },
        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)            
        },        
        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'            
        },
        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
        },
        formatNum(value){ 
           if(!value) return 0  
           return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")          
        }
    },

    created () { 
       this.getdNotifTemplateList();  
    },
    methods: { 

      getFilterData(){          
         this.data  = this.list;
      },

      ActionDelete(id){
          var result = confirm("Are you sure want to Delete Template ?");
          if (result) { this.deleteNotifTemplate(id); }
      },     

      deleteNotifTemplate: function(id) {
        AccountService.deleteNotifTemplate(id).then(resp => {  //console.log(resp);
            if(resp.message==="Success"){
              alert("Delete Template Success!"); this.getdNotifTemplateList();
            } else {
              this.msg   =  !resp.data.message ? "Delete Template Failed!" : resp.data.message; 
              this.color = 'danger'; this.seen  = true;  
              setTimeout( () =>  this.seen=false , 5000);               
            } 

        }, error => {
          this.loading = false;
        });
      },  
      
      getdNotifTemplateList: function() {
         AccountService.getdNotifTemplateList().then(resp => { //console.log(resp);
            this.items = resp;
         }, error => { this.loading = false; });
      }   

    }
 }; 

</script>

<style>
    .scroll-r{ overflow-y:scroll; }
</style>